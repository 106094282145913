import React from "react";
import {
  ApartmentOutlined,
  CarOutlined,
  CloudDownloadOutlined,
  CodeSandboxOutlined,
  ControlOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeFilled,
  FileAddOutlined,
  FileTextOutlined,
  FileZipOutlined,
  GroupOutlined,
  HomeOutlined,
  InboxOutlined,
  PercentageOutlined,
  QuestionOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";

import NotFoundPage from "./pages/404Page";

import DashboardLayout from "./layouts/dashboard";
import Login from "./pages/login";
import DirectUse from "./pages/directUse";

const HomePageComponent = React.lazy(() => import("./pages/homePage"));
const ManifestsPageComponent = React.lazy(() => import("./pages/manifests"));
const ManifestGroupsPageComponent = React.lazy(() => import("./pages/manifestGroups"));
const VendorsPageComponent = React.lazy(() => import("./pages/vendors"));
const ManifestItemsPageComponent = React.lazy(() => import("./pages/manifestItems"));
const DownloadLogsPageComponent = React.lazy(() => import("./pages/downloadLogs"));
const UploadedManifestPageComponent = React.lazy(() => import("./pages/uploadedManifests"));
const UploadedManifestItemsPageComponent = React.lazy(() => import("./pages/uploadedManifestItems"));
const DownloadSettingsPageComponent = React.lazy(() => import("./pages/downloadSettings"));
const DownloadPalletSettingsPageComponent = React.lazy(() => import("./pages/downloadPalletSettings"));
const ItemFieldConfigsPageComponent = React.lazy(() => import("./pages/itemFieldConfigs"));
const ReviewProductListPageComponent = React.lazy(() => import("./pages/reviewProductList"));
const MultiplesProductListPageComponent = React.lazy(() => import("./pages/multiplesProductList"));
const AccountSettingsPageComponent = React.lazy(() => import("./pages/accountSettings"));
const ConditionFiltersPageComponent = React.lazy(() => import("./pages/conditionFilters"));
const ProductConditionsPageComponent = React.lazy(() => import("./pages/productConditions"));
const UserManagementPageComponent = React.lazy(() => import("./pages/userManagement"));
const SupportTicketsPageComponent = React.lazy(() => import("./pages/supportTickets"));
const SupportTicketPageComponent = React.lazy(() => import("./pages/supportTicket"));
const ManifestItemPageComponent = React.lazy(() => import("./pages/manifestItem"));
const ECommerceListItems = React.lazy(() => import("./pages/eCommerceListItems"));
const EcommerceSettingPage = React.lazy(() => import("./pages/eCommerceSetting"));
const ShopifyListings = React.lazy(() => import("./pages/eCommerceShopifyListing"));
const SquareListings = React.lazy(() => import("./pages/eCommerceSquareListing"));
const CloverListings = React.lazy(() => import("./pages/eCommerceCloverListing"));
const QueueStatus = React.lazy(() => import("./pages/eCommerceQueueStatus"));
const QueueReview = React.lazy(() => import("./pages/eCommerceQueueReview"));
const QueueDetail = React.lazy(() => import("./pages/eCommerceQueueReview/queueDetail"));
const DownloadListingSetting = React.lazy(() => import("./pages/downloadListingSetting"));

export const INDEX_ROUTE = "/";
export const REGISTER_ROUTE = "/register";
export const MANIFESTS_ROUTE = "/manifests";
export const MANIFEST_GROUPS_ROUTE = "/manifest-groups";
export const VENDORS_ROUTE = "/vendors";
export const MANIFEST_ITEMS_ROUTE = "/manifest-items/:manifestId";
export const DOWNLOAD_LOGS_ROUTE = "/download-logs";
export const UPLOADED_MANIFESTS_ROUTE = "/uploaded-manifests";
export const UPLOADED_MANIFEST_ITEMS_ROUTE = "/uploaded-manifest-items/:uploadedManifestId";
export const DOWNLOAD_SETTINGS_ROUTE = "/download-settings";
export const DOWNLOAD_PALLET_SETTINGS_ROUTE = "/download-pallets-settings";
export const ITEM_FIELD_CONFIGS_ROUTE = "/item-attributes";
export const REVIEW_PRODUCT_LIST_ROUTE = "/review-product-list";
export const MULTIPLES_PRODUCT_LIST_ROUTE = "/multiples-product-list";
export const ACCOUNT_SETTINGS_ROUTE = "/account-settings";
export const CONDITION_FILTERS_ROUTE = "/condition-filters";
export const PRODUCT_CONDITIONS_ROUTE = "/product-conditions";
export const USER_MANAGEMENT_ROUTE = "/users";
export const DIRECT_USE_ROUTE = "/direct-use-adapter/:jwt/:adminJwt";
export const SUPPORT_TICKETS_ROUTE = "/support-tickets";
export const SUPPORT_TICKET_ID_ROUTE = "/support-ticket/:supportTicketId";
export const MANIFEST_ITEM_ROUTE = "/manifest-item/:manifestItemId";
export const ECOMMERCE_LIST_ITEM_ROUTE = "/ecommerce-list-items";
export const ECOMMERCE_SETTING_ROUTE = "/ecommerce-setting";
export const SHOPIFY_LIST_ITEM_ROUTE = "/shopify-list-items";
export const SQUARE_LIST_ITEM_ROUTE = "/square-list-items";
export const CLOVER_LIST_ITEM_ROUTE = "/clover-list-items";
export const QUEUE_STATUS_ROUTE = "/queue-status";
export const QUEUE_REVIEW_ROUTE = "/queue-review ";
export const QUEUE_REVIEW_DETAIL_ROUTE = "/queue-review/:queueId";
export const DOWNLOAD_LISTINGS_SETTINGS = "/download-listings-settings";

export const NON_LOGIN_ROUTES = [
  {
    exact: true,
    name: "login",
    path: INDEX_ROUTE,
    component: Login,
  },
  {
    exact: true,
    name: "login",
    path: DIRECT_USE_ROUTE,
    component: DirectUse,
  },
  {
    component: NotFoundPage,
  },
];

export const LOGGED_IN_ROUTES = [
  {
    name: "layout",
    path: INDEX_ROUTE,
    component: DashboardLayout,
    routes: [
      {
        exact: true,
        name: "home",
        path: INDEX_ROUTE,
        component: HomePageComponent,
      },
      {
        exact: true,
        name: "login",
        path: DIRECT_USE_ROUTE,
        component: DirectUse,
      },
      {
        exact: true,
        name: "manifests",
        path: MANIFESTS_ROUTE,
        component: ManifestsPageComponent,
      },
      {
        exact: true,
        name: "manifest-groups",
        path: MANIFEST_GROUPS_ROUTE,
        component: ManifestGroupsPageComponent,
      },
      {
        exact: true,
        name: "vendors",
        path: VENDORS_ROUTE,
        component: VendorsPageComponent,
      },
      {
        exact: true,
        name: "manifest-items",
        path: MANIFEST_ITEMS_ROUTE,
        component: ManifestItemsPageComponent,
      },
      {
        exact: true,
        name: "download-logs",
        path: DOWNLOAD_LOGS_ROUTE,
        component: DownloadLogsPageComponent,
      },
      {
        exact: true,
        name: "uploaded-manifests",
        path: UPLOADED_MANIFESTS_ROUTE,
        component: UploadedManifestPageComponent,
      },
      {
        exact: true,
        name: "uploaded-manifest-items",
        path: UPLOADED_MANIFEST_ITEMS_ROUTE,
        component: UploadedManifestItemsPageComponent,
      },
      {
        exact: true,
        name: "download-settings",
        path: DOWNLOAD_SETTINGS_ROUTE,
        component: DownloadSettingsPageComponent,
      },
      {
        exact: true,
        name: "download-pallet-settings",
        path: DOWNLOAD_PALLET_SETTINGS_ROUTE,
        component: DownloadPalletSettingsPageComponent,
      },
      {
        exact: true,
        name: "item-attributes",
        path: ITEM_FIELD_CONFIGS_ROUTE,
        component: ItemFieldConfigsPageComponent,
      },
      {
        exact: true,
        name: "review-product-list",
        path: REVIEW_PRODUCT_LIST_ROUTE,
        component: ReviewProductListPageComponent,
      },
      {
        exact: true,
        name: "multiples-product-list",
        path: MULTIPLES_PRODUCT_LIST_ROUTE,
        component: MultiplesProductListPageComponent,
      },
      {
        exact: true,
        name: "account-settings",
        path: ACCOUNT_SETTINGS_ROUTE,
        component: AccountSettingsPageComponent,
      },
      {
        exact: true,
        name: "condition-filters",
        path: CONDITION_FILTERS_ROUTE,
        component: ConditionFiltersPageComponent,
      },
      {
        exact: true,
        name: "product-conditions",
        path: PRODUCT_CONDITIONS_ROUTE,
        component: ProductConditionsPageComponent,
      },
      {
        exact: true,
        name: "user-management",
        path: USER_MANAGEMENT_ROUTE,
        component: UserManagementPageComponent,
      },
      {
        exact: true,
        name: "support-tickets",
        path: SUPPORT_TICKETS_ROUTE,
        component: SupportTicketsPageComponent,
      },
      {
        exact: true,
        name: "support-ticket",
        path: SUPPORT_TICKET_ID_ROUTE,
        component: SupportTicketPageComponent,
      },
      {
        exact: true,
        name: "manifest-item",
        path: MANIFEST_ITEM_ROUTE,
        component: ManifestItemPageComponent,
      },
      {
        exact: true,
        name: "list-items",
        path: ECOMMERCE_LIST_ITEM_ROUTE,
        component: ECommerceListItems,
      },
      {
        exact: true,
        name: "ecommerce-settings",
        path: ECOMMERCE_SETTING_ROUTE,
        component: EcommerceSettingPage,
      },
      {
        exact: true,
        name: "shopify-listings",
        path: SHOPIFY_LIST_ITEM_ROUTE,
        component: ShopifyListings,
      },
      {
        exact: true,
        name: "square-listings",
        path: SQUARE_LIST_ITEM_ROUTE,
        component: SquareListings,
      },
      {
        exact: true,
        name: "clover-listings",
        path: CLOVER_LIST_ITEM_ROUTE,
        component: CloverListings,
      },
      {
        exact: true,
        name: "square-listings",
        path: SQUARE_LIST_ITEM_ROUTE,
        component: SquareListings,
      },
      {
        exact: true,
        name: "queue-status",
        path: QUEUE_STATUS_ROUTE,
        component: QueueStatus,
      },
      {
        exact: true,
        name: "queue-review",
        path: QUEUE_REVIEW_ROUTE,
        component: QueueReview,
      },
      {
        exact: true,
        name: "queue-review-detail",
        path: QUEUE_REVIEW_DETAIL_ROUTE,
        component: QueueDetail,
      },
      {
        exact: true,
        name: "queue-review-detail",
        path: DOWNLOAD_LISTINGS_SETTINGS,
        component: DownloadListingSetting,
      },
    ],
  },
  {
    component: NotFoundPage,
  },
];

export const SIDEBAR_ITEMS = [
  {
    name: "home",
    label: "Dashboard",
    route: INDEX_ROUTE,
    icon: <DashboardOutlined />,
  },
  {
    name: "/manifest-generator",
    label: "Manifest Generator",
    icon: <ApartmentOutlined />,
    submenu: [
      {
        name: "manifests",
        label: "Manifests",
        route: MANIFESTS_ROUTE,
        icon: <ReconciliationOutlined />,
      },
      {
        name: "manifest-groups",
        label: "Manifest Groups",
        route: MANIFEST_GROUPS_ROUTE,
        icon: <GroupOutlined />,
      },
      {
        name: "uploaded-manifests",
        label: "Uploaded Manifests",
        route: UPLOADED_MANIFESTS_ROUTE,
        icon: <FileTextOutlined />,
      },
      {
        name: "review-product-list",
        label: "Review Product List",
        route: REVIEW_PRODUCT_LIST_ROUTE,
        icon: <PercentageOutlined />,
      },
      {
        name: "multiples-product-list",
        label: "Multiples Product List",
        route: MULTIPLES_PRODUCT_LIST_ROUTE,
        icon: <FileAddOutlined />,
      },
      {
        name: "vendors",
        label: "Vendors",
        route: VENDORS_ROUTE,
        icon: <ShoppingCartOutlined />,
      },
      // {
      //   name: "download-receiving",
      //   label: "Download Receiving",
      //   icon: <CloudDownloadOutlined />,
      //   downloadReceiving: true,
      //   inventoryModuleRequired: true,
      // },
    ],
  },
  {
    name: "/ecommerce",
    label: "eCommerce",
    icon: <ShoppingOutlined />,
    submenu: [
      {
        name: "list-items",
        label: "List Items",
        route: ECOMMERCE_LIST_ITEM_ROUTE,
        icon: <ReconciliationOutlined />,
      },
      {
        name: "shopify-listings",
        label: "Shopify Listings",
        route: SHOPIFY_LIST_ITEM_ROUTE,
        icon: <FileTextOutlined />,
      },
      {
        name: "square-listings",
        label: "Square Listings",
        route: SQUARE_LIST_ITEM_ROUTE,
        icon: <FileTextOutlined />,
      },
      {
        name: "clover-listings",
        label: "Clover Listings",
        route: CLOVER_LIST_ITEM_ROUTE,
        icon: <FileTextOutlined />,
      },
      {
        name: "queue-status",
        label: "Queue Status",
        route: QUEUE_STATUS_ROUTE,
        icon: <FileAddOutlined />,
      },
      {
        name: "queue-review",
        label: "Queue Review",
        route: QUEUE_REVIEW_ROUTE,
        icon: <EyeFilled />,
      },
      // {
      //   name: "download-receiving",
      //   label: "Download Receiving",
      //   icon: <CloudDownloadOutlined />,
      //   downloadReceiving: true,
      //   inventoryModuleRequired: true,
      // },
    ],
  },
  {
    name: "/settings",
    label: "Settings",
    icon: <ControlOutlined />,
    submenu: [
      {
        name: "user-management",
        label: "User Management",
        route: USER_MANAGEMENT_ROUTE,
        icon: <GroupOutlined />,
        roleRequired: "AccountAdmin",
      },
      {
        name: "account-settings",
        label: "Account Settings",
        route: ACCOUNT_SETTINGS_ROUTE,
        icon: <SettingOutlined />,
        roleRequired: "AccountAdmin",
      },
      {
        name: "condition-filters",
        label: "Condition Filters",
        route: CONDITION_FILTERS_ROUTE,
        icon: <SnippetsOutlined />,
      },
      {
        name: "download-settings",
        label: "Download Settings",
        route: DOWNLOAD_SETTINGS_ROUTE,
        icon: <DownloadOutlined />,
      },
      {
        name: "download-pallet-settings",
        label: "Download Pallet(s) Settings",
        route: DOWNLOAD_PALLET_SETTINGS_ROUTE,
        icon: <DownloadOutlined />,
        inventoryModuleRequired: true,
      },
      {
        name: "ecommerce-settings",
        label: "eCommerce Settings",
        route: ECOMMERCE_SETTING_ROUTE,
        icon: <ShoppingOutlined />,
      },
      {
        name: "item-attributes",
        label: "Item Attributes",
        route: ITEM_FIELD_CONFIGS_ROUTE,
        icon: <EditOutlined />,
      },
      {
        name: "product-conditions",
        label: "Product Conditions",
        route: PRODUCT_CONDITIONS_ROUTE,
        icon: <CodeSandboxOutlined />,
      },
      {
        name: "download-listings-settings",
        label: "Download Listings Settings",
        route: DOWNLOAD_LISTINGS_SETTINGS,
        icon: <CodeSandboxOutlined />,
      },
    ],
  },
  {
    name: "/download",
    label: "Download",
    icon: <FileZipOutlined />,
    submenu: [
      {
        name: "download-logs",
        label: "Download Logs",
        route: DOWNLOAD_LOGS_ROUTE,
        icon: <FileZipOutlined />,
      },
    ],
  },
  {
    name: "/support-tickets",
    label: "Support Ticket(s)",
    icon: <QuestionOutlined />,
    route: SUPPORT_TICKETS_ROUTE,
    display_support_ticket_count: true,
  },
];
